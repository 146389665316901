/** @define contact-form; weak */
.contact-form {
  width: 100%;
  color: #bebebe;
}
.contact-form__form {
  width: 100%;
}
.contact-form__form input,
.contact-form__form textarea {
  background-color: #fafafa;
  border: 1px solid #bebebe;
}
.contact-form__form input {
  width: 100%;
  padding: 8px 10px;
}
.contact-form__form textarea {
  min-width: 100%;
  max-width: 100%;
  height: 205px;
  padding: 8px 10px;
}
.contact-form__sectiontitle {
  margin: 40px 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #444;
}
.contact-form__sectiontitle span {
  font-size: 12px;
}
@media only screen and (min-width: 1100px) {
  .contact-form__title {
    width: 200px;
    margin-bottom: 5px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 1100px) {
  .contact-form__content {
    display: flex;
    flex-wrap: wrap;
  }
}
.contact-form__input {
  margin-bottom: 20px;
}
@media only screen and (min-width: 750px) {
  .contact-form__input .form_error {
    position: relative;
    display: block;
  }
}
@media only screen and (min-width: 1100px) {
  .contact-form__input {
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1100px) {
  .contact-form__input {
    width: calc(100% - 200px);
  }
}
.contact-form__icdic {
  margin-bottom: 0;
}
.contact-form__icdic .contact-form__input {
  width: 49%;
}
@media only screen and (min-width: 1100px) {
  .contact-form__icdic .contact-form__input {
    width: 50%;
  }
  .contact-form__icdic .contact-form__input:first-child {
    margin-right: 5px;
  }
}
.contact-form__street,
.contact-form__city {
  margin-bottom: 0;
}
.contact-form__street .contact-form__input:first-child,
.contact-form__city .contact-form__input:first-child {
  width: 69%;
}
@media only screen and (min-width: 1100px) {
  .contact-form__street .contact-form__input:first-child,
  .contact-form__city .contact-form__input:first-child {
    width: 70%;
    margin-right: 5px;
  }
}
.contact-form__street .contact-form__input:last-child,
.contact-form__city .contact-form__input:last-child {
  width: 29%;
}
@media only screen and (min-width: 1100px) {
  .contact-form__street .contact-form__input:last-child,
  .contact-form__city .contact-form__input:last-child {
    width: 30%;
  }
}
.contact-form__sectiontitle--button {
  line-height: 30px;
  cursor: pointer;
  transition: color 0.4s ease-in-out;
}
.contact-form__sectiontitle--button:hover {
  color: #f59a00;
}
.contact-form__sectiontitle--button .icon_open {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
/* postcss-bem-linter: ignore */
input[type="submit"] {
  width: auto;
  padding: 10px 14px !important;
  color: #ffffff;
  text-transform: uppercase;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
  background-color: #f59a00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  /* postcss-bem-linter: ignore */
}
input[type="submit"]:hover {
  color: #ffffff;
  background-color: #229660;
}
/* postcss-bem-linter: ignore */
.form_error {
  background: url("../../../images/form_error.png") no-repeat left center #ff0004;
}
/* postcss-bem-linter: ignore */
.form_succes {
  background: url("../../../images/form_error.png") no-repeat left center #33a01d;
}
/* postcss-bem-linter: ignore */
.form_error,
.form_succes {
  display: inline-block;
  margin-top: 5px;
  padding: 5px 15px 5px 27px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
}
/*# sourceMappingURL=css/contact-form.css.map */